.nav-bar {
  background: #181818;
  width: 65px;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 45px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    width: 100%;
    top: 30%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }
    
    a.projects-link {
      &:after {
        content: 'PROJECTS';
      }
    }

    a.active {
      svg {
        color: #ff4af3;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;

        &:hover {
          color: #ffd700;
        }
      }
    }
  }
}

//media query for mobile
@media screen and (max-width: 979px) {
  .nav-bar {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    min-height: 0;

    .logo {
      display: block;
      padding: 8px 0;
      padding-left: 10px;
      position: relative;
      left: 0cm;
  
      img {
        display: block;
        margin: 8px auto;
        width: 30px;
        height: auto;
  
        &.sub-logo {
          width: 40px;
        }
      }
    }
  
    nav {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      text-align: center;
      height: auto;
      width: full;
      top: 0%;
  
      a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 30px;
        padding-right: 3.5vh;
        padding-left: 2vh;
        height: 30px;
        position: relative;
        text-decoration: none;
  
        i {
          transition: all 0.3s ease-out;
        }
  
        &:hover {
          color: #ffd700;
  
          svg {
            opacity: 0;
          }
  
          &:after {
            opacity: 1;
          }
        }
  
        &:after {
          content: '';
          font-size: 8px;
          letter-spacing: 1px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: left;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
  
        &:first-child {
          &:after {
            content: 'HOME';
          }
        }
      }
  
      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }
  
      a.contact-link {
        &:after {
          content: 'C0NTACT';
        }
      }
      
      a.projects-link {
        &:after {
          content: 'PROJECTS';
        }
      }
  
      a.active {
        svg {
          color: #ff4af3;
        }
      }
    }
  
    ul {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: none;
      justify-content: flex-end;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;
  
      li {
        a {
          padding: 5px 0;
          display: block;
          font-size: 25px;
          line-height: 16px;
          color: #4d4d4e;
  
          &:hover {
            color: #ffd700;
          }
        }
      }
    }
}



}