.home-page {
  overflow-x: hidden;
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ff4af3;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ff00ee;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    position: absolute;
  }

  .flat-button {
    color: black;
    background-color: #ff4af3;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ff4af3;
    margin-top: 50px;
    

    &:hover {
      border: 1px solid #fff;
    }
  }


  .resume{
   display: none;
   
  }
  .resume2 {
    height: 80vh;
    width: 60vw;
    display: block;
    margin: auto;
    margin-top: 75vh;
    padding-bottom: 5vh;
 }

}


@media screen and (max-width: 767px){    

  .home-page {
    .text-zone {
      left: 5%;
      transform: translateY(-20%);
      width: 90%;
    }

    .resume2 {
      height: 70vh;
      width: 100vw;
      display: block;
      margin: auto;
      margin-top: 75vh;
      padding-bottom: 5vh;
   }
  
   
  }


}


